<template>
	<div class="box">
		<div class="row">
			<div class="col-12">
				<label>{{ $t('pax.dispo_pays_stallion') }}</label>
				<e-select
					track-by="id"
					label="name"
					:options="countries_iso_array"
					:searchable="true"
					:allow-empty="true"
					:show-labels="false"
					:multiple="true"
					:search="true"
					:placeholder="labelTitle"
					:deselectLabel="deselectLabel"
					:selectedLabel="selectedLabel"
					:selectLabel="enter_to_select"
					v-model="countries_dispo"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
					<template slot="option" slot-scope="props">
						{{ props.option.name }}
					</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
				<div class="text-center mt-3">
					<b-button pill variant="primary" class="px-5" @click="saveCountries">
		                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
		                <font-awesome-icon v-else :icon="['fal', 'save']"></font-awesome-icon> {{ $t("global.enregistrer") }}
		            </b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Countries from '@/assets/lang/countries/countries'
	import PaxMixin from "@/mixins/Pax.js"

	export default {
		name: 'HorsePaxPays',
		mixins: [PaxMixin],
		props: ['horse_id'],
		data () {
			return {
				countries_dispo: [],
				countries_iso_array_temp: [],
				countries_iso_array: [],
				labelTitle: this.getTrad("global.press_enter_to_remove"),
				deselectLabel: this.getTrad("global.selected_label"),
				selectedLabel: this.getTrad("global.press_enter_to_select"),
				enter_to_select: this.getTrad("compta.search_country"),
				processing: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const countries = Countries[Vue.i18n.locale()].label
				const countries_iso = Countries[Vue.i18n.locale()].iso_3

				let countries_values = Object.values(countries)
				let countries_iso_values = Object.values(countries_iso)

				let temp = []
				for (let i = 0; i < countries_values.length; i++) {
					temp.push(countries_values[i]+' - '+countries_iso_values[i])
				}

				let countries_iso_array_temp = []
				let countries_keys = Object.keys(countries)
				let countries_formatted = Object.fromEntries(countries_keys.map((_, i) => [countries_keys[i], temp[i]]))
				for (let [key, value] of Object.entries(countries_formatted)) {
					countries_iso_array_temp.push({
						id: key,
						name: value
					})
				}
				this.countries_iso_array = countries_iso_array_temp.sort((a,b) => {
					// return a.name - b.name;
					var x = this.accentsTidy(a.name.toLowerCase());
				    var y = this.accentsTidy(b.name.toLowerCase());
				    return x < y ? -1 : x > y ? 1 : 0;
				});

				const stallions_countries = await this.getStallionCountries(this.horse_id)
				if(stallions_countries.length > 0) {
					const countries_ids = stallions_countries.map(country => country.disponibilitepays_pays)
					this.countries_dispo = this.countries_iso_array.filter(country => countries_ids.includes(parseInt(country.id)))
				}
			},

			async saveCountries() {
				this.processing = true
				const countries_ids = this.countries_dispo.map(country => country.id)
				await this.saveStallionCountries(this.horse_id, countries_ids)
				this.processing = false
			}
		},
	}

</script>